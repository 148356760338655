import React, { useState } from "react";
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as needed
import * as FaIcons from "react-icons/fa";

const Footer = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasReadTerms, setHasReadTerms] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = (form) => {
    const errors = {};
    if (!form.first_name.value.trim()) errors.first_name = "Name is required.";
    if (!form.user_email.value.trim()) errors.user_email = "Email is required.";
    if (!hasReadTerms) errors.hasReadTerms = "You must be over 18 years of age.";
    return errors;
  };

  const saveToFirestore = async (e) => {
    e.preventDefault();
    const formErrors = validateForm(e.target);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setErrors({});
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, 'emailList'), {
        name: e.target.first_name.value.trim(),
        email: e.target.user_email.value.trim(),
      });
      alert('Information saved successfully!');
    } catch (error) {
      console.error('Error saving to Firestore:', error);
      alert('Something went wrong!');
    } finally {
      setIsSubmitting(false);
      e.target.reset(); // Clears the form after saving the data
    }
  };

  return (
    <div className="footer bg-soopaorange bg-cover text-white bottom-0 flex flex-col px-8">
      <div className="footer-content flex items-start justify-between pb-4 w-full">
        <div className="pt-8 sm:text-4xl w-full">
          <span className="flex"><FaIcons.FaCheck className='mr-4'/>get notified when SOOPA FAT goes live</span>
          <span className="flex"><FaIcons.FaCheck className='mr-4'/>exclusive rates for original members</span>
          <span className="flex"><FaIcons.FaCheck className='mr-4'/>early access to articles, videos and apps</span>
          <span className="flex"><FaIcons.FaCheck className='mr-4'/>no spam, we promise</span>
          <form onSubmit={saveToFirestore} className="flex flex-col text-xl mt-8 font-sans font-light">
            <input type="text" name="first_name" className="text-black p-1 mb-1 w-full" placeholder="name*" />
            {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
            <input type="email" name="user_email" className="text-black p-1 mb-1 w-full" placeholder="email*" />
            {errors.user_email && <p className="text-red-500 text-sm">{errors.user_email}</p>}
            <div className="flex items-center justify-between mb-4">
              <div>
                <input 
                  type="checkbox" 
                  checked={hasReadTerms} 
                  onChange={() => setHasReadTerms(!hasReadTerms)} 
                  className="mr-2"
                />
                <label onClick={() => setHasReadTerms(!hasReadTerms)}>i am over 18 years old</label>
              </div>
              <input 
                type="submit" 
                value="sign up" 
                className="mt-4 border-white border-2 rounded-3xl border-white py-1 px-4 text-white hover:bg-soopablue hover:cursor-pointer" 
                disabled={isSubmitting} 
                />
            </div>
            {errors.hasReadTerms && <p className="text-red-500 text-sm">{errors.hasReadTerms}</p>}
          </form>
        </div>
      </div>
      <div className="copyright text-left border-t-2 border-solid border-fadedwhite pb-4">
        <p className='text-base opacity-70'>
          &copy; 2024 SOOPA FAT. all rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
