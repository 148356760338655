import React, { useRef } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import LandingTitle from '../assets/LandingTitle.png';
import FatShibaLine from '../assets/FatShibaLine.png';
import Momo from '../assets/Momo.png';

const Home = () => {
  // ===== Scramble Text =====
  
  const footerRef = useRef(null);
  const servicesRef = useRef(null);
  
  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  const scrollToServices = () => {
    servicesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='home flex flex-col w-screen h-auto text-white bg-soopablue bg-cover font-anton'>
      <Navbar />
      <div className='flex flex-col justify-center items-center'>
        <img src={LandingTitle} alt='' className='px-4'></img>
        <div className='buttons flex my-8 sm:mb-16'>
          <button
            onClick={scrollToFooter}
            className='flex items-center border-2 rounded-3xl border-solid border-white py-1 px-8 pr-2 sm:py-2 sm:pr-10 bg-soopaorange text-2xl sm:text-6xl mr-1 sm:mr-4 hover:bg-[#FF905D]'
          >
            <IoIcons.IoMdFitness className='mr-2'/>get FAT
          </button>
          <button
            onClick={scrollToServices}
            className='border-2 rounded-3xl border-solid border-white py-1 px-8 text-2xl sm:text-6xl hover:bg-soopadarkblue'
          >
            learn more
          </button>
        </div>
      </div>
      <div ref={servicesRef} className='flex items-center flex-col bg-soopablue w-full px-8'>
        <img src={FatShibaLine} alt='keyboardshibaleft' className='sm:w-[700px] sm:h-[200px] w-40 h-16 mt-4'></img>
        <h1 className="text-4xl sm:text-6xl mt-12 mb-6 text-center"><span className='text-soopapeach'>the </span>SOOPA FAT <span className='text-soopapeach'>experience </span></h1>
        <p className='font-sans font-light text-xl sm:text-3xl text-center lg:mx-40'>want to stop feeling like shit everyday? ready to make a change?</p>
        <p className='font-sans font-light text-xl sm:text-3xl text-center lg:mx-40'>never fear, for SOOPA FAT is coming soon.</p>
        <div className='md:flex md:my-8 lg:mx-40'>
          <div className='py-1 px-4 border-fadedwhite border-2 border-solid rounded-3xl text-xl basis-1/2 m-2'>
            <h1 className='flex items-center font-sans font-light text-xl sm:text-2xl sm:text-2xl mb-1 border-b-2 border-fadedwhite'><FaIcons.FaBrain className='mr-2 p-1 bg-soopaorange rounded-2xl' />knowledge</h1>
            <p className='font-sans font-light italic text-lg sm:text-2xl'>science-backed diet and workout coaching, no bullshit, satisfaction guaranteed</p>
          </div>
          <div className='py-1 px-4 border-fadedwhite border-2 border-solid rounded-3xl text-xl basis-1/2 m-2'>
            <h1 className='flex items-center font-sans font-light text-xl sm:text-2xl mb-1 border-b-2 border-fadedwhite'><FaIcons.FaHome className='mr-2 p-1 bg-soopaorange rounded-2xl' />community</h1>
            <p className='font-sans font-light italic text-lg sm:text-2xl'>some of the friendliest, non-toxic people on the planet, here to help and support</p>
          </div>
          <div className='py-1 px-4 border-fadedwhite border-2 border-solid rounded-3xl text-xl basis-1/2 m-2'>
            <h1 className='flex items-center font-sans font-light text-xl sm:text-2xl mb-1 border-b-2 border-fadedwhite'><IoIcons.IoIosApps className='mr-2 p-1 bg-soopaorange rounded-2xl' />applications</h1>
            <p className='font-sans font-light italic text-lg sm:text-2xl'>exclusive, custom tools for planning, executing, and tracking your lifestyle change</p>
          </div>
        </div>
      </div>
      <div className='flex items-center flex-col w-full px-8'>
        <h1 className="text-4xl sm:text-6xl my-4 mb-8 text-center">about <span className='text-soopapeach'>us </span></h1>
        <div className="md:flex px-48">
          <img src={Momo} alt='Momo' className='max-w-96 max-h-64'></img>
          <p className='font-sans font-light mt-4 px-8 text-xl sm:text-3xl text-center'>our chief woof officer runs the show here, aided by melon and jihan, a couple of health enthusiasts with too many degrees and too much passion to stay quiet.</p>
        </div>
      </div>
      <div ref={footerRef} className='mt-12'>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
